import React from 'react';
import Container from 'react-bootstrap/Container';

import './scss/terms.scss';


const PrivacyPolicy = () => {
    const copyrightYear = new Date().getFullYear();
    return (
        <Container>
            <div className='text-center'>
                <h1 className='page-header'>Privacy Policy</h1>
            </div>
            <div className='text-container'>
                <h4>EveryDVM LLC Privacy Policy</h4>
                <p>At EveryDVM, the privacy of our visitors to everydvm.com is one of our main priorities. This Privacy Policy document contains types of information that is collected and recorded by EveryDVM and how we use it.
                </p>
                <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collected in EveryDVM. This policy is not applicable to any information collected offline or via channels other than this website.
                </p>   
                <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms. Please contact us if you have questions or require more information about our Privacy Policy.
                </p>

                <h4>Collection of Your Personal Information</h4>
                <p>In order to better serve you, EveryDVM may collect personally identifiable information such as your:
                </p>
                <ul>
                    <li>First and last name</li>
                    <li>Email address</li>
                    <li>Phone number</li>
                </ul>
                <p>If you contact us directly, we may receive additional information about you including, but not limited to your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
                </p>
                <h4>Use of Your Information</h4>
                <p>EveryDVM collects and uses your personal information to operate and deliver the services you have requested.
                </p>
                <p>EveryDVM may also use your personally identifiable information to send you service-related communications and inform you of other products and services available from EveryDVM and its affiliates.
                </p>
                <h4>Log Files</h4>
                <p>Information about your computer hardware and software may be automatically collected by EveryDVM. This information can include your IP address, browser type, domain names, access times, and referring website addresses. This information is not linked to any information that is personally identifiable. This information is used for the operation of the website, to maintain the quality of the website, and to provide general statistics regarding use of the EveryDVM website.
                </p>

                <h4>Cookies and Web Beacons</h4>
                <p>Like any other website, EveryDVM uses ‘cookies’. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and/or other information.
                </p>
                <p>For more information about cookies, and how to disable cookies, visit <a href='http://www.allaboutcookies.org' target='_blank' rel='noreferrer'>http://www.allaboutcookies.org</a>.
                </p>
                <h4>Links</h4>
                <p>This website may contain links to other sites. Please be aware that we are not responsible for the content or privacy practices of these third-party sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.</p>

                <h4>CCPA Privacy Rights</h4>
                <p>Under the CCPA, among other rights, California consumers have the right to:
                </p>
                <ul>
                    <li>Know about the personal information a business collects about them and how it is used and shared</li>
                    <li>Request that a business deletes any personal information collected from them</li>
                    <li>Opt-out of the sale or sharing of their personal information</li>
                    <li>Correct inaccurate personal information that a business has about them</li>
                    <li>Limit the use and disclosure of sensitive personal information collected about them</li>
                    <li>Non-discrimination for exercising their CCPA rights</li>
                </ul>
                <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
                </p>

                <h4>GDPR Data Protection Rights</h4>
                <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the right to:
                </p>
                <ul>
                    <li><strong>Access: </strong>You have the right to request copies of your personal data. We may charge you a small fee for this service.</li>
                    <li><strong>Rectification: </strong>You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</li>
                    <li><strong>Delete: </strong>You have the right to request that we delete your personal data, under certain conditions.</li>
                    <li><strong>Restrict Processing: </strong>You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
                    <li><strong>Object to Processing: </strong>You have the right to object to our processing of your personal data, under certain conditions.</li>
                    <li><strong>Data Portability: </strong>You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
                </ul>
                <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
            
                <h4>Protection of Children</h4>
                <p>
                    EveryDVM will not knowingly collect, use or disclose Personal Identifiable Information from children under the age of 13. EveryDVM abides by laws protecting the privacy of children. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
                </p>
                
                <h4>Modifications and Updates to Privacy Statement</h4>
                <p>
                    This Privacy Statement becomes effective on January 23, 2023. EveryDVM may update and modify this Privacy Statement at any time and from time to time, and such updates and modifications will be effective immediately upon posting the updated or modified Privacy Statement. You agree to review this Privacy Statement periodically to be aware of such updates and modifications and your continued access or use of the Website after any updates and modifications will be deemed your conclusive acceptance of the updated or modified Privacy Statement.
                </p>
                <p>© EveryDVM LLC - {copyrightYear}</p>
            </div>

        </Container>
    );
};

export default PrivacyPolicy;