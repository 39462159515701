const snackbarType = {
    BAD_MESSAGE: {
        type: 'bad',
        icon: 'bi-x-circle',
        theme: 'toast-fail'
    },
    GOOD_MESSAGE: {
        type: 'good',
        icon: 'bi-check-circle',
        theme: 'toast-success'
    },
    WARNING_MESSAGE: {
        type: 'warning',
        icon: 'bi-exclamation-triangle',
        theme: 'toast-warning'
    },
    INFO_MESSAGE: {
        type: 'info',
        icon: 'bi-info-circle',
        theme: 'toast-info'
    }
};

export default snackbarType;