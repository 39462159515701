import { Container } from 'react-bootstrap';

import './scss/main_stakes.scss';

import imgDogHead from '../../assets/images/favicon-dark.png';

const MainStakes = () => {
    return (
        <div className='main-stakes-container'>
            <Container>
                <h3 className='main-stakes'>
                    The typical web design project leaves a <span className='display-6 stakes-script'>lot</span> to be desired.
                </h3> 
                <div className='main-stakes-row'>
                    <div className='main-stakes-column'>
                        <img src={imgDogHead} alt='' />
                        <div>Poor organization and slow turn-around on updates</div>
                    </div>
                    <div className='main-stakes-column'>
                        <img src={imgDogHead} alt='' />
                        <div>Designers who don&apos;t understand your business or get your vision</div>
                    </div>
                    <div className='main-stakes-column'>
                        <img src={imgDogHead} alt='' />
                        <div>Little to no guidance with search optimization and site analytics</div>
                    </div>
                    <div className='main-stakes-column'>
                        <img src={imgDogHead} alt='' />
                        <div>Lack of continuous innovation and ongoing feature enhancements</div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default MainStakes;