import React from 'react';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';

import LogoDark from '../../assets/images/logoEveryDVM_Dark.webp';

import './footer.scss';

const Footer = () => {

    const copyrightYear = new Date().getFullYear();

    return (
        <Container fluid className='mt-auto footer-container'>
            <Container className='footer-content-container'>
                <div className='footer-section-container'>
                    <div className='footer-logo-container'>
                        <Link to='/'>
                            <img className='footer-logo-image' src={LogoDark} alt='logo' />
                        </Link>
                    </div>
                    <div className='footer-address-container'>
                        <span>PO Box 1448</span>
                        <span className='mb-4'>Eagle, ID 83616</span>
                        <span>info@everydvm.com</span>
                    </div>
                </div>
                <div className='footer-section-container'>
                    <Link to='/services'>Services</Link>
                    <Link to='/pricing'>Pricing</Link>
                    <Link to='/about-us'>About Us</Link>
                    <Link to='/contact-us'>Contact Us</Link>
                </div>
                <hr className='divider' />
                <div className='footer-section-container'>
                    <Link to='/terms'>Terms of Use</Link>
                    <Link to='/privacy'>Privacy Policy</Link>
                    <span>© {copyrightYear}&nbsp;&nbsp;EveryDVM LLC</span>
                </div>
            </Container>
        </Container>
    );
};

export default Footer;