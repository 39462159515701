import React from 'react';
import { ToastContainer, Toast } from 'react-bootstrap';

import './scss/snackbar_toast.scss';

const SnackbarToast = (props) => {
    const { toast, message, onClose } = props;

    return (
        <ToastContainer containerPosition='fixed' position='middle-center'>
            <Toast 
                onClose={onClose} 
                className={toast.theme} 
                delay={4500} 
                autohide
            >
                <Toast.Header>
                    <strong className='me-auto'>EveryDVM</strong>
                </Toast.Header>
                <Toast.Body><i className={toast.icon} />{message}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
};

export default SnackbarToast;