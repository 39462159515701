import React, { useEffect, useState } from 'react';
import { CloseButton, Container } from 'react-bootstrap';

import './cookie_banner.scss';

const CookieBanner = () => {

    const [cookieAccepted, setCookieAccepted] = useState(false);

    const handleButtonClick = () => {
        const cookieAcceptDate = new Date();
        localStorage.setItem('everydvm-cookie-accept', cookieAcceptDate);
        setCookieAccepted(true);
    };
    
    useEffect(() => {
        const cookieAcceptDate = localStorage.getItem('everydvm-cookie-accept');
        if (cookieAcceptDate) {
            setCookieAccepted(true);
        }
    }, [cookieAccepted]);

    return !cookieAccepted && (
        <Container fluid className='cookie-banner-container'>
            <span className='cookie-banner-text'>
                By using this website, you agree to our use of cookies. 
                We use cookies to provide you with a great experience and to help our website run effectively.
            </span>
            <span className='cookie-banner-button'>
                <CloseButton variant='white' aria-label='Hide' onClick={handleButtonClick} />
            </span>
        </Container>
    );
};

export default CookieBanner;