import { Container } from 'react-bootstrap';

import EveryCard from '../global/every_card';

import './scss/main_plan.scss';

import imgMainVetPhone from '../../assets/images/imgVetPhone.webp';

const MainPlan = () => {
    return (
        <Container fluid className='main-plan-container'>
            <Container>
                <div className='main-plan-inner-container'>
                    <EveryCard 
                        image={imgMainVetPhone} 
                        title={<div>Here&apos;s How <span className='every-card-text-script'>It Works</span></div>} 
                        bodyText={
                            <ol>
                                <li><strong>Schedule a call</strong> - we&apos;d love to hear your ideas and share how EveryDVM can help your business. When you&apos;re ready, we&apos;ll get started!</li>
                                <li><strong>Gather and build</strong> - you&apos;ll work with a designer to gather the text, photos, and other materials we&apos;ll need to design your site. We&apos;ll share the progress every step of the way as we build.</li>
                                <li><strong>Launch!</strong> - once you&apos;ve given us final approval, we&apos;ll launch your site for the world to see.</li>
                            </ol>}
                        buttonText='GET STARTED TODAY'
                        buttonURL='/contact-us'
                        verticalOrientation='top'
                        horizontalOrientation='left'
                    />
                </div>
            </Container>
        </Container>
    );
};

export default MainPlan;