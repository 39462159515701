import apiURI from 'src/enum/api-uri';
import method from 'src/enum/method';
import useApi from 'src/hooks/use-api';
import uiResponse from 'src/hooks/use-uiResponse';

const EVERYDVM_TOKEN = 'everydvm_token';

const useMessageService = () => {
    const api = useApi();
    const myToken = localStorage.getItem(EVERYDVM_TOKEN);

    const sendMessage = async messageData => {
        try {
            const res = await api.request({
                method: method.POST,
                uri: apiURI.AUTH,
                route: '/v1/messages',
                data: messageData,
                token: myToken
            });

            return res;

        } catch (e) {
            return uiResponse (false, {
                error: true,
                exception: e,
                errorMessage: e.message
            });
        }
    };

    return {
        sendMessage
    };

};

export default useMessageService;