import React, { useRef, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import 'src/App.scss';

import AppContextProvider from 'src/context/app-context';
import ScrollToTop from 'src/mod/scroll_to_top';

import Header from 'src/components/header/header';
import Main from 'src/components/main/main';
import AboutUs from 'src/components/about_us/about_us';
import Services from 'src/components/services/services';
import Pricing from 'src/components/pricing/pricing';
import ContactUs from 'src/components/contact_us/contact_us';
import TermsOfUse from 'src/components/terms/terms_of_use';
import PrivacyPolicy from 'src/components/terms/privacy_policy';
import Footer from 'src/components/footer/footer';
import CookieBanner from 'src/components/cookie_banner/cookie_banner';

import SnackbarToast from 'src/components/global/snackbar_toast';

function App() {

    const snackType = useRef();
    const snackMessage = useRef();
    const [showSnackbarToast, setShowSnackbarToast] = useState(false);

    const showSnack = (type, message) => {
        snackType.current = type;
        snackMessage.current = message;
        setShowSnackbarToast(true);
    };

    const hideSnack = () => {
        setShowSnackbarToast(false);
    };

    return (
        <AppContextProvider>
            <BrowserRouter>
                <ScrollToTop>
                    <Header />
                    <Routes>
                        <Route exact path='/' element={<Main showSnack={showSnack} />} />
                        <Route exact path='/about-us' element={<AboutUs showSnack={showSnack} />} />
                        <Route exact path='/services' element={<Services showSnack={showSnack} />} />
                        <Route exact path='/pricing' element={<Pricing showSnack={showSnack} />} />
                        <Route exact path='/contact-us' element={<ContactUs showSnack={showSnack} />} />
                        <Route exact path='/terms' element={<TermsOfUse />} />
                        <Route exact path='/privacy' element={<PrivacyPolicy />} />
                    </Routes>
                    {showSnackbarToast &&
                        <SnackbarToast
                            toast={snackType.current}
                            message={snackMessage.current}
                            onClose={hideSnack}
                        />
                    }
                    <Footer />
                    <CookieBanner />
                </ScrollToTop>
            </BrowserRouter>
        </AppContextProvider>
    );
}

export default App;