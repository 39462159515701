import React from 'react';
import Container from 'react-bootstrap/Container';

import './scss/terms.scss';


const TermsOfUse = () => {
    const copyrightYear = new Date().getFullYear();
    return (
        <Container>
            <div className='text-center'>
                <h1 className='page-header'>Terms of and Conditions of Use</h1>
            </div>
            <div className='text-container'>
                <p>Welcome to EveryDVM! The following terms and conditions outline the rules and regulations for the use of everydvm.com (this Website).
                </p>
                <p>EveryDVM LLC maintains everydvm.com and related websites that refer to these Terms and Conditions of Use (Terms of Use) as a service to its customers and prospective customers. These Terms of Use constitute an agreement between you and EveryDVM, and are sometimes referred to herein as “this Agreement”. The terms “we”, “us”, and “our” refer to EveryDVM, and the terms “you” or “yours” refers to you, as a user of this Website. 
                </p>  
                <p>If you do not wish to be bound by these Terms of Use, you should not access or use this Website and you must terminate your use immediately.
                </p>
                <p>Proceeding to use this Website indicates your acknowledgment you have read and accepted these Terms of Use. You also represent that you are at least 18 years of age and possess the legal capacity to enter into this Agreement. You may print a copy of these Terms of Use for your records at any time by using the “print” function on your Internet browser.
                </p>

                <h4>Cookies</h4>
                <p>We employ the use of cookies. By accessing EveryDVM, you agreed to use cookies in agreement with EveryDVM’S <a href='/privacy'>Privacy Policy</a>.
                </p>
                <p>Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.
                </p>

                <h4>EveryDVM Privacy Policy</h4>
                <p>EveryDVM has a <a href='/privacy'>Privacy Policy</a> that sets forth the privacy policies and practices of EveryDVM as they relate to the collection, use, processing and disclosure of information in connection with your use of this Website. The EveryDVM Privacy Policy is incorporated into these Terms of Use by reference, as if set forth fully herein. Any changes to the EveryDVM Privacy Policy will be automatically incorporated into the Terms of Use, effective upon posting of such changes.
                </p>
        
                <h4>Copyrights, Trademarks, and IP Rights</h4>
                <p>The entire contents of everydvm.com are copyrighted under United States’ copyright laws by EveryDVM. No part of this everydvm.com may be reproduced, stored in a retrieval system, transmitted or retransmitted in any form or by any means, electronic, mechanical, photocopying, recording or otherwise, without the prior written permission of EveryDVM. You may print and download portions of material from the different areas of everydvm.com solely for your own informational, non-commercial, individual and internal reference, provided that you reproduce the copyright notice which appears at the end of these Terms of Use. Any content, software and other material (Content) downloaded from everydvm.com are the property of EveryDVM or its licensors and is protected by United States and international copyright and intellectual property laws and treaty provisions. All rights to patents, copyrights, trademarks, service marks, trade secrets and other intangible property rights in the Content or any modifications to it shall remain in EveryDVM and its licensors. To the extent any Content consists of software, you may not modify, reverse engineer, decompile, disassemble or create derivative works based on the Content, or remove any proprietary notices or labels that it contains. Subject to the foregoing, you may make: 
                </p>
                <ul>
                    <li>one machine-readable copy</li>
                    <li>one backup copy</li>
                    <li>one print copy of any portions of material downloaded from different areas of everydvm.com solely for your own informational, non-commercial, individual reference, provided that you reproduce the copyright notice which appears at the end of these Term of Use Copyrights</li>
                </ul>
                <p>Any other copying, distribution or publication is strictly prohibited without the express prior written consent of EveryDVM. Any unauthorized use terminates any permission or license granted by EveryDVM.
                </p>
                <p>EveryDVM service and product names are trademarks and/or service marks of EveryDVM LLC, its affiliates and/or its subsidiaries. These and all other names, logos and marks on everydvm.com are owned or licensed by EveryDVM and may not be used by you without express prior written permission from EveryDVM.
                </p>
                
                <h4>Content Liability</h4>
                <p>EveryDVM shall not be held responsible for any content that appears on your website. You agree to protect and defend us against all claims that are rising on your website. No link(s) should appear on any website that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
                </p>

                <h4>Inclusion of Other Notices and Terms</h4>
                <p>This Website may contain other notices, terms and conditions and copyright information, the terms of all of which must be observed and followed. Information provided on this Website does not constitute an offer to sell any particular product or service or engage in any other commercial transaction until EveryDVM accepts your order or the parties enter into a mutual agreement. You may have other agreements with EveryDVM. Those agreements are separate and in addition to these Terms of Use. These Terms of Use do not modify, revise or amend the terms of any other agreements you may have with EveryDVM.
                </p>

                <h4>Links</h4>
                <p>Links in this Website may allow you to leave EveryDVM’s Website or be sent to another third-party’s website. EveryDVM is providing these links to you only as a convenience, and the inclusion of any link neither implies endorsement by EveryDVM of the linked site nor obligates you to click the link. Third-party linked sites are not under the control of EveryDVM and EveryDVM is not responsible for the contents of any linked site or any link contained in a linked site, or any changes or updates to such sites. 
                </p>
                <p>You are responsible for taking precautions to ensure that whatever you select for your use is free of code that manifests contaminating or destructive properties, and other items of a harmful or destructive nature. It is also your responsibility to review the privacy policies on any linked sites, as their privacy rules and standards may be different than EveryDVM’s and EveryDVM is not responsible for their privacy policies or practices. You should also review the terms and conditions of any linked sites. 
                </p>
                <p>EveryDVM welcomes bookmarks and links to everydvm.com for lawful purposes. You are free to establish a hypertext link to our site as long as the link does not state or imply any sponsorship or endorsement of your site by EveryDVM or any of its affiliates. EveryDVM reserves the right, in its sole discretion at any time, to deny any request, or rescind any permission granted, to link to this Website and to require termination of any such link to this Website. You may not frame any content of everydvm.com or utilize any framing techniques in any way, including but not limited to enclosing any trademark, logo, or other proprietary information (including, but not limited to, images, text, page layout, or form), without EveryDVM’s prior written consent. You may also not incorporate any intellectual property contained on everydvm.com or owned by EveryDVM or its licensors. You may not use any meta tags or any other “hidden text” utilizing EveryDVM’s name or trademarks without the express written consent of EveryDVM. IN NO EVENT WILL EveryDVM, ITS AFFILIATES, SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES OR ASSOCIATES BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR ANY OTHER DAMAGES WHATSOEVER INCLUDING BUT NOT LIMITED TO, LOST PROFITS OR BUSINESS INTERRUPTION DAMAGES EVEN IF EveryDVM OR ITS REPRESENTATIVES HAVE BEEN SPECIFICALLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, FOR ANY USE OF THE LINKED SITES SET FORTH IN THIS SECTION.
                </p>

                <h4>No Unlawful or Prohibited Use</h4>
                <p>As a condition of your use of this Website and Content, you agree not to use this Website or its Content for any purpose that is unlawful or prohibited by these terms, conditions, and notices. You agree not to use this Website or its Content in any manner that could damage, disable, overburden, or impair any EveryDVM server, or the network(s) connected to any EveryDVM server, or interfere with any other party’s use and enjoyment of this Website and its Content. You agree not to attempt to gain unauthorized access to this Website, other accounts, computer systems or networks connected to any EveryDVM server or to this Website, through hacking, password mining or any other means. You also agree to not obtain or attempt to obtain any materials or information through any means not intentionally made available through this Website or its Content.
                </p>

                <h4>Disclaimer and Limit on Damages</h4>
                <p>You understand that EveryDVM cannot and does not guarantee that Content available from or on this Website will be free of infection, viruses or other code that manifest contaminating or destructive properties. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for accuracy of data input and output, and for maintaining a means external to this Website for the reconstruction of any lost data. You assume all responsibility and risk for your use of this Website, including your responsibility to evaluate the accuracy, completeness and usefulness of all material and reports provided through or generated by this Website. EveryDVM does not warrant that this Website will be uninterrupted or error-free or that defects in this Website will be corrected. Content, including any software provided on this Website and related communications, are provided “as is” for information purposes only and EveryDVM makes no guarantee or warranty with respect to correctness, reliability, accuracy or appropriateness of the material or the results obtained from the use of the services provided on this Website. You agree that use of this Website is at your own risk. In addition, all warranties, either express or implied, are hereby disclaimed, including warranties of performance, merchantability, fitness for a particular purpose (even if EveryDVM or its authorized representatives have been advised of the possibility of any such purpose), or non-infringement. EveryDVM has no obligation or duty to update any information on this Website.
                </p>
                <p>IN NO EVENT WILL EveryDVM, ITS AFFILIATES, SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES OR ASSOCIATES, OR ANY PARTY INVOLVED IN CREATING, PRODUCING, HOSTING OR DISTRIBUTING THIS WEBSITE BE LIABLE TO YOU OR ANY PARTY FOR ANY DIRECT, INDIRECT, SPECIAL OR OTHER CONSEQUENTIAL DAMAGES, INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA ON YOUR INFORMATION HANDLING SYSTEM OR OTHERWISE, EVEN IF EveryDVM OR ITS AUTHORIZED REPRESENTATIVES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, FOR ANY CLAIM ATTRIBUTABLE TO USE OF THIS WEBSITE, INCLUDING ANY DAMAGES DUE TO ERRORS, OMISSIONS, OR OTHER INACCURACIES IN THIS WEBSITE OR CONTENT OR OTHER MATERIAL DOWNLOADED THROUGH OR HYPER LINKED FROM THIS WEBSITE. IN ANY EVENT, THE TOTAL OF ALL DAMAGES, INCLUDING DIRECT DAMAGES (WHETHER GROUNDED IN CONTRACT, TORT OR OTHERWISE) ARISING UNDER OR RELATED TO THIS WEBSITE, OR THE RESULTS OBTAINED THEREFROM, WILL BE LIMITED TO THE AGGREGATE AMOUNT OF ALL SUMS PAID BY YOU TO EveryDVM FOR SERVICES PROVIDED THROUGH THIS WEBSITE DURING THE ONE (1) MONTH PRIOR TO THE DATE ON WHICH SUCH DAMAGES WERE FIRST INCURRED, OR IF YOU DO NOT PAY FOR ANY SERVICES RENDERED TO YOU THROUGH THIS WEBSITE, $100. BECAUSE SOME STATES DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE MAY NOT APPLY TO YOU. IN SUCH STATES, EveryDVM’S LIABILITY IS LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
                </p>

                <h4>Indemnity</h4>
                <p>You agree to indemnify, defend and hold harmless EveryDVM and its affiliates and their respective officers, directors, employees, agents, licensors, suppliers and any third-party information providers to this Website from and against all losses, expenses, damages and costs, including reasonable attorneys’ fees, resulting from any information you provide or from any violation of this Agreement by you.
                </p>

                <h4>Waiver and Release</h4>
                <p>You hereby release and forever waive any and all claims you may have against EveryDVM, its affiliates, subsidiaries, officers, directors, employees, agents, associates, representatives and suppliers for losses or damages you sustain in connection with your use of this Website. This waiver may not apply to gross negligence by EveryDVM or any other claims that cannot be lawfully waived or limited.
                </p>

                <h4>Applicable Law</h4>
                <p>To the extent there is a conflict between any provision of this Agreement and any present law or regulation contrary to which the parties may not agree, such law or regulation will prevail; however, in such event, the affected provisions will be limited only to the extent necessary to bring them within the requirements of the law and to otherwise carry out the purposes of this Agreement. By accessing or using this Website, you agree it is intended to be accessed and used only within the United States, Canada and the United Kingdom, and will be only construed according to United States law. Any access or use from other locations is subject to your compliance with all applicable local laws, and EveryDVM will not be held responsible or liable for your use of this Website in such other locations. EveryDVM makes no representation that material on this Website is appropriate outside the United States, Canada or the United Kingdom. Information concerning pharmaceutical distribution services applies only to services provided within these countries and is subject to all applicable state and local laws.
                </p>

                <h4>Updates and Modifications to Terms of Use</h4>
                <p>These Terms of Use become effective on January 23, 2023. EveryDVM may update and modify this Agreement at any time and from time to time, and such updates and modifications will be effective immediately upon posting the updated/modified Agreement. You agree to review this Agreement periodically to be aware of such updates and modifications and your continued access or use of this Website after any updates and modifications will be deemed your conclusive acceptance of the updated/modified Agreement.
                </p>
                <p>© EveryDVM LLC - {copyrightYear}</p>
            </div>

        </Container>
    );
};

export default TermsOfUse;