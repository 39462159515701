import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
    const location = useLocation();

    const scrollUp = async () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollUp();
    }, [location]);

    return (
        <>
            {children}
        </>
    );
};

export default ScrollToTop;