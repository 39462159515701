const regexPatterns = {
    EMAIL: '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$',
    NICKNAME: '^[a-zA-Z0-9_.]{1,31}[a-zA-Z]+[0-9]*$',
    PASSWORD: '^(?=.{8,})(?=.*[!@#$%^&*])',
    URL: 'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)',
    DATE_MMDDYYYY: '^(1[0-2]|0[1-9])/(3[01]|[12][0-9]|0[1-9])/[0-9]{4}$',
    PHONE: '^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$',
    NUMBER: '^[0-9]*$'
};

export default regexPatterns;