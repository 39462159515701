import React, { useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReCAPTCHA from 'react-google-recaptcha';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Container, Form } from 'react-bootstrap';

import snackbarType from '../../enum/snackbar_type';
import templateType from '../../enum/email_template_type';
import FormsSchema from '../../schema/forms';

import useMessageService from '../../service/message_service';

import EveryHero from '../global/every_hero';
import PageLead from '../global/page_lead';

import './contact_us.scss';

import imgHeroContactUs from '../../assets/images/imgHappyDog2.jpg';

export default function ContactUs(props) {
    const { showSnack } = props;
    const messageService = useMessageService();
    const recaptchaRef = useRef();

    const { contactUsForm } = FormsSchema;

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(contactUsForm)
    });

    const [submitIsDisabled, setSubmitIsDisabled] = useState(true);
    const [recaptchaToken, setRecaptchaToken] = useState('');

    const reCaptchaSiteKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

    const handleToken = (token) => {
        setRecaptchaToken(token);
        setSubmitIsDisabled(false);
    };

    const handleTokenExpired = async () => {
        setRecaptchaToken('');
        setSubmitIsDisabled(true);
        await recaptchaRef.current?.reset();
    };

    const onSubmit = async (data) => {

        const messageData = {
            visitor_name: data.full_name,
            visitor_email: data.email,
            visitor_phone: data.phone,
            visitor_message: data.message,
            recipient: import.meta.env.VITE_SENDGRID_TO_EMAIL,
            recipient_name: import.meta.env.VITE_SENDGRID_TO_NAME,
            recipient_org_name: import.meta.env.VITE_SENDGRID_TO_ORG_NAME,
            template_name: templateType.CONTACT_US,
            recaptchaToken
        };

        const res = await messageService.sendMessage(messageData);

        if (res && res.status === 200) {
            showSnack(snackbarType.GOOD_MESSAGE, 'Your message has been sent!');
        }

        setValue('full_name', null);
        setValue('email', null);
        setValue('phone', null);
        setValue('message', null);
        setRecaptchaToken('');
        setSubmitIsDisabled(true);
        await recaptchaRef.current.reset();
    };

    return (
        <>
            <EveryHero image={imgHeroContactUs} title='Contact Us' />
            <PageLead
                text={<>Contact us <span className='page-lead-text-script'>today</span> to start the conversation on your new website!</>}
                subtext={<>Let&apos;s set up a video call where you can get to know us, tell us about your vision and ask questions. There&apos;s no time like the present!</>}
            />
            <Container className='max-width-xl'>
                <div className='main-contact-form'>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className='main-contact-form-input'>
                            <Form.Label htmlFor='full_name'>Full Name<span className='text-danger'> *</span></Form.Label>
                            <Form.Control
                                id='full_name'
                                type='text'
                                {...register('full_name')}
                                className={`${errors.full_name ? 'is-invalid' : ''}`}
                            />
                            {errors.full_name && <span className='main-contact-form-input-error text-danger'>{errors.full_name.message}</span>}
                        </Form.Group>
                        <Form.Group className='main-contact-form-input'>
                            <Form.Label htmlFor='email'>Email Address<span className='text-danger'> *</span></Form.Label>
                            <Form.Control
                                id='email'
                                type='text'
                                {...register('email')}
                                className={`${errors.email ? 'is-invalid' : ''}`}
                            />
                            {errors.email && <span className='main-contact-form-input-error text-danger'>{errors.email.message}</span>}
                        </Form.Group>
                        <Form.Group className='main-contact-form-input'>
                            <Form.Label htmlFor='phone'>Phone Number</Form.Label>
                            <Form.Control
                                id='phone'
                                type='text'
                                {...register('phone')}
                            />
                        </Form.Group>
                        <Form.Group className='main-contact-form-input mb-3'>
                            <Form.Label htmlFor='message'>Message Text</Form.Label>
                            <Form.Control as='textarea' rows={5}
                                id='message'
                                type='text'
                                {...register('message')}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                type='hidden'
                                {...register('recaptchaToken')}
                                value={recaptchaToken}
                            />
                            <div className='mb-3'>
                                <ReCAPTCHA
                                    sitekey={reCaptchaSiteKey}
                                    onChange={handleToken}
                                    onExpired={handleTokenExpired}
                                    ref={recaptchaRef}
                                />
                            </div>
                        </Form.Group>
                        <div className='main-contact-disclaimer'>
                            * Required <br />
                            EveryDVM never sells or shares your information
                        </div>
                        <Button variant='primary' type='submit' disabled={submitIsDisabled}>
                            SUBMIT
                        </Button>
                    </Form>
                </div>
            </Container>
        </>
    );
};