// Main page loader
import React from 'react';

import MainHero from './main_hero';
import MainStakes from './main_stakes';
import MainValueProp from './main_value_prop';
import MainGuide from './main_guide';
import MainPlan from './main_plan';
// import CallToAction from '../global/call_to_action';

const Main = (props) => {
    // const { showSnack } = props;
    return (
        <>
            <MainHero />
            <MainStakes />
            <MainValueProp />
            <MainGuide />
            <MainPlan />
            {/* <CallToAction showSnack={showSnack} /> */}
        </>
    );
};

export default Main;