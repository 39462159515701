import React from 'react';

import { Button, Container, Nav, Navbar } from 'react-bootstrap';

import Logo from '../../assets/images/logoEveryDVM.webp';

import './header.scss';

const Header = () => {
    return (
        <Container fluid className='header-container'>
            <Container>
                <div className='header-inner-container'>
                    <Navbar expand='lg'>
                        <Navbar.Brand href='/'><img className='header-logo' src={Logo} alt='logo' /></Navbar.Brand>
                        <Navbar.Toggle aria-controls='basic-navbar-nav' />
                        <Navbar.Collapse id='basic-navbar-nav' className='justify-content-end'>
                            <Nav>
                                <Nav.Link href='/about-us'>About Us</Nav.Link>
                                <Nav.Link href='/services'>Services</Nav.Link>
                                <Nav.Link href='/pricing' className='me-3'>Pricing</Nav.Link>
                            </Nav>
                            <Button variant='primary' href='/contact-us' className='call-to-action-button header-nav-button'>GET STARTED TODAY</Button>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </Container>
        </Container>
    );
};

export default Header;